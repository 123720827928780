/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html {
  font-size: 10px;
  overflow: hidden;
}

button {
  border: none;
  outline: none;
}

.ant-popover-inner {
  border-radius: 8px !important;
}

.ant-popover-message-title {
  font-size: 16px !important;
  padding-left: 32px !important;
}

.ant-calendar-picker-container {
  max-width: 40vw !important;
  overflow-x: auto;
}
